import Header from './components/Header'
import HeroSix from './hero/HeroSix'
import FeaturesOne from './features/FeaturesOne'
import StatsOne from './stats/StatsOne'
import AssetsTwo from './assets/AssetsTwo'
import CTAThree from './cta/CTAThree'
import FooterSeven from './footer/FooterSeven'
import PricingTwo from './pricing/PricingTwo'
import { useEffect } from 'react'

const Main = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header />
    <HeroSix />
    <br />
    <FeaturesOne />
    <StatsOne />
    <AssetsTwo />
    <PricingTwo />
    <CTAThree />
    <FooterSeven />
    </>
  )
}

export default Main